.under-construction {
  height: 100vh;
  width: 100vw;

  background: linear-gradient(150deg, tan 50%, var(--jtjs-theme-background) 30%);

  $animationDuration: 1s;
  .info-container {
    animation: info-entry $animationDuration * 2 ease normal;
  }

  .jtjs-heading {
    animation: heading-entry $animationDuration ease normal;
    margin: 0;
    color: white;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
}

@keyframes heading-entry {
  from {
    filter: opacity(0);
    transform: translateX(-25%);
  }

  to {
    transform: translateX(0);
    filter: opacity(1);
  }
}

@keyframes info-entry {
  from {
    filter: opacity(0);
    transform: translateY(15%);
  }

  50% {
    filter: opacity(0);
    transform: translateY(15%);
  }

  to {
    filter: opacity(1);
    transform: translateY(0%);
  }
}
